// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-js": () => import("/tmp/gatsby-loosdorf/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-entry-js": () => import("/tmp/gatsby-loosdorf/src/templates/entry.js" /* webpackChunkName: "component---src-templates-entry-js" */),
  "component---src-pages-404-js": () => import("/tmp/gatsby-loosdorf/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apotheke-impressum-js": () => import("/tmp/gatsby-loosdorf/src/pages/apotheke/impressum.js" /* webpackChunkName: "component---src-pages-apotheke-impressum-js" */),
  "component---src-pages-apotheke-kontakt-js": () => import("/tmp/gatsby-loosdorf/src/pages/apotheke/kontakt.js" /* webpackChunkName: "component---src-pages-apotheke-kontakt-js" */),
  "component---src-pages-index-js": () => import("/tmp/gatsby-loosdorf/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

