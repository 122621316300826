import React from "react"

import "./sidebar.styl"

export default () => (
  <ul id="sidebar">
    <li>
      <div className="image">
        <svg
          className="svg-inline--fa fa-clock fa-w-16 icon"
          aria-hidden="true"
          data-prefix="far"
          data-icon="clock"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
          ></path>
        </svg>
        {/*<i className="icon far fa-clock" />*/}
      </div>
      <div
        id="time-dropdown"
        className="info"
        uk-dropdown="pos: right-center; delay-hide: 150; offset: 30; mode: click;"
      >
        <p style={{ whiteSpace: "pre" }}>
          <b>&Ouml;ffnungszeiten</b>
          <br />
          Mo-Fr: 08:00-18:00
          <br />
          Sa: 08:00-12:00
          <br />
        </p>
      </div>
    </li>
    <li>
      <div className="image">
        <svg
          className="svg-inline--fa fa-moon fa-w-16 icon"
          aria-hidden="true"
          data-prefix="far"
          data-icon="moon"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M279.135 512c78.756 0 150.982-35.804 198.844-94.775 28.27-34.831-2.558-85.722-46.249-77.401-82.348 15.683-158.272-47.268-158.272-130.792 0-48.424 26.06-92.292 67.434-115.836 38.745-22.05 28.999-80.788-15.022-88.919A257.936 257.936 0 0 0 279.135 0c-141.36 0-256 114.575-256 256 0 141.36 114.576 256 256 256zm0-464c12.985 0 25.689 1.201 38.016 3.478-54.76 31.163-91.693 90.042-91.693 157.554 0 113.848 103.641 199.2 215.252 177.944C402.574 433.964 344.366 464 279.135 464c-114.875 0-208-93.125-208-208s93.125-208 208-208z"
          ></path>
        </svg>
        {/*<i className="icon far fa-moon" />*/}
      </div>
      <div
        id="moon-dropdown"
        className="info"
        uk-dropdown="pos: right-center; delay-hide: 150; offset: 30; mode: click;"
      >
        <p style={{ whiteSpace: "pre" }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.apotheker.or.at/internet/oeak/Apotheken.nsf/webApothekensuche?Readform"
          >
            Nacht- und Notdienst-Apotheken suchen
          </a>
        </p>
      </div>
    </li>
  </ul>
)
