import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import Navbar from "../components/Navbar"
import Sidebar from "../components/Sidebar"
import Footer from "../components/Footer"

import "../assets/uikit-3.1.8/css/uikit.css"
import "../assets/global.styl"
import "./default.styl"

import logo from '../images/logo_loosdorf.svg'

const DefaultLayout = ({ children }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const uikit = require("uikit");
      const icons = require("uikit/dist/js/uikit-icons");

      uikit.use(icons);
    }
  })

  return (
    <div className="default">
      <Helmet>
        <link data-n-head="true" rel="stylesheet" href="https://use.typekit.net/jko1zly.css" />
        
        {/* font-awesome */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.min.css" integrity="sha256-UzFD2WYH2U1dQpKDjjZK72VtPeWP50NoJjd26rnAdUI=" crossorigin="anonymous" />
      </Helmet>

      <Link id="logo-top" to="/">
        <img src={logo} alt="logo_loosdorf" />
      </Link>
      <Navbar />
      {children}
      <Sidebar />

      <Footer />
    </div>
  )
}

export default DefaultLayout
