import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import logoSmall from '../images/logo-small.svg'

import "./navbar.styl"

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.subMenuRef = React.createRef()
    this.state = {
      isDesktop: null,
      dropdownId: null,
      dropdownSubId: null,
      hamburgerOpen: false,
      arrowStyleLeft: null,
      opacity: null,
      left: null,
      width: null,
      height: null,
      subMenu: [],
      subSection: [],
    }
  }

  componentDidMount() {
    const { current } = this.subMenuRef
    this.windowDesktop = window.matchMedia("(min-width: 1000px)")

    if (current) {
      const subSection = Array.from(current.querySelectorAll(".top-section"))

      this.setState({
        subMenu: Array.from(
          current.querySelectorAll(".dropdown-menu__content")
        ),
        subSection,
      })

      if (!this.windowDesktop.matches) {
        subSection.forEach(sub => {
          sub.dataset.height = sub.getBoundingClientRect().height
          sub.style.height = this.windowDesktop.maches ? "auto" : "0px"
        })
      }
    }

    this.isDesktop = e => {
      this.setState({
        isDesktop: e.matches,
        opacity: null,
        left: null,
        width: null,
        height: null,
      })

      this.state.subSection.forEach(sub => {
        sub.dataset.height = sub.getBoundingClientRect().height
        sub.style.height = this.state.isDesktop ? "auto" : "0px"
      })
    }

    this.isDesktop(this.windowDesktop)
    this.windowDesktop.addListener(this.isDesktop)
  }

  componentWillUnmount() {
    this.windowDesktop.removeListener(this.isDesktop)
  }

  render() {
    let closeDropdownTimeout

    const { alloy } = this.props.data

    // load data from graphql (missing menue list)
    const { productCategories, storyCategories, pages } = alloy

    // create main-pages via data
    const pages_fix = pages.filter(({ category }) => !category)
    const parents = pages_fix.filter(({ parent }) => !parent.length)
    const menu = parents.map(({ _id, title, path }) => ({
      id: _id,
      text: title,
      link: path ? `/${path}` : "/",
      children: pages_fix
        .filter(
          ({ parent }) => parent && parent.length && parent[0] && parent[0]._id === _id
        )
        .map(child => ({
          _id: child._id,
          name: child.title,
          link: `/${path}/${child.path}`,
        })),
    }))

    // create categories-pages via data
    const CategoryList = {
      stories: storyCategories,
      produkte: productCategories,
    }
    const categoryPages = pages
      .filter(({ category }) => category)
      .map(({ _id, title, path }) => ({
        id: _id,
        text: title,
        prefix: path,
        children: CategoryList[path], // TODO make it more flexible
      }))

    const MenuData = menu.concat(categoryPages);

    const startCloseTimeout = () => {
      closeDropdownTimeout = setTimeout(() => closeDropdown(), 100)
    }

    const stopCloseTimeout = () => {
      clearTimeout(closeDropdownTimeout)
    }

    const openDropdown = (element, id) => {
      const menuRect = element.getBoundingClientRect()
      const sub = this.state.subMenu.find(child => child.id === id)
      const subRect = sub.getBoundingClientRect()

      if (!sub) {
        console.warn("There is no SubMenue for that Menu.")
        startCloseTimeout()
        return
      }

      this.setState({
        arrowStyleLeft: menuRect.left + menuRect.width / 2 - 10 + "px",
        left: menuRect.left - (subRect.width / 2 - menuRect.width / 2) + "px",
        width: subRect.width + "px",
        height: subRect.height + "px",
      })
    }

    const closeDropdown = () => {
      this.setState({
        dropdownId: null,
        opacity: "0",
      })
    }

    const handleMouseEnter = (event, id) => {
      this.setState({
        dropdownId: id,
        opacity: "1",
      })

      stopCloseTimeout()
      openDropdown(event.currentTarget, id)
    }

    const handleMouseLeave = () => {
      startCloseTimeout()
    }

    const openHamburger = id => {
      toggleHamburger(true)
      this.state.subSection.forEach(item => {
        item.style.height =
          item.dataset.id === id ? item.dataset.height + "px" : "0px"
      })
    }

    const toggleHamburger = open => {
      this.setState({
        hamburgerOpen: open ? open : !this.state.hamburgerOpen,
      })
    }

    return (
      <div
        id="navbar"
        className={this.state.hamburgerOpen ? "opened" : ""}
        uk-sticky={this.isDesktop ? "offset: 0" : ""}
      >
        <header
          className={
            this.state.dropdownId
              ? "main-header dropdown-active"
              : "main-header"
          }
        >
          <Link to="/">
            <img id="logo-small" src={logoSmall} alt="logo-small.svg" />
          </Link>

          <ul className="menu">
            {MenuData.map(({ id, text, children }, index) => (
              <li
                key={id}
                className={
                  id === this.state.dropdownId
                    ? "menu__item active"
                    : "menu__item"
                }
                onClick={() =>
                  !this.state.isDesktop && index !== 0 && openHamburger(id)
                }
                onMouseEnter={e =>
                  this.state.isDesktop &&
                  children &&
                  children.length &&
                  handleMouseEnter(e, id, text)
                }
                onMouseLeave={e =>
                  this.state.isDesktop &&
                  children &&
                  children.length &&
                  handleMouseLeave()
                }
              >
                {index === 0 ? (
                  <Link to="/">{text.toUpperCase()}</Link>
                ) : (
                  <div className="link-nolink">{text.toUpperCase()}</div>
                )}
              </li>
            ))}
          </ul>

          <span
            className={
              this.state.hamburgerOpen
                ? "hamburger-button open"
                : "hamburger-button"
            }
            uk-icon="icon: menu;"
            onClick={() => toggleHamburger()}
          />

          <div
            className="dropdown-holder"
            style={
              !this.state.isDesktop
                ? this.state.hamburgerOpen
                  ? { top: "8.5vh", opacity: "1", pointerEvents: "all" }
                  : { top: "-50vh", opacity: "0", pointerEvents: "none" }
                : {}
            }
          >
            <div
              className="dropdown__arrow"
              style={{
                opacity: this.state.opacity,
                left: this.state.arrowStyleLeft,
              }}
            />
            <div
              className="dropdown__bg"
              style={{
                opacity: this.state.opacity,
                left: this.state.left,
                width: this.state.width,
                height: this.state.height,
              }}
            />
            <div
              className="dropdown__wrap"
              style={{
                opacity: this.state.opacity,
                left: this.state.left,
                width: this.state.width,
                height: this.state.height,
              }}
              ref={this.subMenuRef}
            >
              {MenuData.filter(
                ({ children }) => children && children.length
              ).map((props, index) => (
                <SubMenue
                  key={index}
                  {...props}
                  startCloseTimeout={startCloseTimeout}
                  stopCloseTimeout={stopCloseTimeout}
                  toggleHamburger={toggleHamburger}
                  dropdownId={this.state.dropdownId}
                  isDesktop={this.state.isDesktop}
                  subSection={this.state.subSection}
                />
              ))}
            </div>
          </div>
        </header>
      </div>
    )
  }
}

const SubMenue = props => {
  const {
    id,
    text,
    prefix,
    children,
    startCloseTimeout,
    stopCloseTimeout,
    toggleHamburger,
    dropdownId,
    isDesktop,
    subSection,
  } = props

  return (
    <div
      data-sub={text}
      className={dropdownId === id ? "dropdown-menu active" : "dropdown-menu"}
      onMouseEnter={e => isDesktop && stopCloseTimeout()}
      onMouseLeave={e => isDesktop && startCloseTimeout()}
      style={dropdownId === id ? { opacity: 1 } : {}}
    >
      <div id={id} className="dropdown-menu__content">
        <div
          data-for={text}
          className="title"
          onClick={() =>
            !isDesktop &&
            subSection.forEach(item => {
              item.style.height =
                item.dataset.id === id ? item.dataset.height + "px" : "0px"
            })
          }
        >
          {text.toUpperCase()}
        </div>
        <div
          className="top-section"
          data-height
          data-id={id}
          onClick={() => !isDesktop && toggleHamburger()}
        >
          <ul>
            {children.map((category, index) => (
              <li key={index}>
                <Link to={category.link || `/${prefix}/${category.slug}`}>
                  {category.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        alloy {
          productCategories {
            _id
            name
            slug
          }
          storyCategories {
            _id
            name
            slug
          }
          pages {
            _id
            title
            path
            category
            parent {
              _id
            }
          }
        }
      }
    `}
    render={data => <NavBar data={data} />}
  />
)
