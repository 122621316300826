import React from "react"
import { Link } from "gatsby"

import "./footer.styl"

// totopVisible on document.documentElement.scrollTop > 300

export default () => (
  <div id="footer">
    <div className="mobile entry">
      <p className="text mobile-adress">
        Apotheke Loosdorf | Wiener Straße 12 | 3382 Loosdorf
        <br />
        Tel.: +43 27 54/63 31 | office@apotheke-loosdorf.at
        <br />
        Mo-Fr: 08:00–18:00 Uhr, Sa. 08:00–12:00 Uhr
        <br />
      </p>
    </div>
    <div className="imp_copy">
      <div className="entry">
        <Link className="text" to="/apotheke/impressum">
          Impressum
        </Link>
      </div>

      <div className="entry uk-align-right">
        <p className="text">© Apotheke Loosdorf {new Date().getFullYear()}</p>
      </div>
    </div>
  </div>
)
